import React from 'react'

const HeaderMob = () => {
	const toggleMenu = () => {
		let showMenu = document.querySelector('.burger')
		let button = document.querySelector('.burger__button-touch')
		let over = document.querySelector('.over')
		if (showMenu.classList.contains('active')) {
			showMenu.classList.remove('active')
			button.classList.remove('open')
			over.classList.remove('close')
		} else {
			showMenu.classList.add('active')
			button.classList.add('open')
			over.classList.add('close')
		}
	}
	return (
		<div>
			<nav className='burger'>
				<ul className='burger__menu'>
					<li>
						< a className='burger__menu-link' href="#home" >
							<span> Home </span>
						</a >
					</li> <li>
					<a className='burger__menu-link'href="#projects">
						<span> Projects </span>
					</a>
				</li>
					<li>
						<a className='burger__menu-link' href="#about">
							<span> About Me</span>
						</a>
					</li>
					<li>
						<a className='burger__menu-link' href="#contacts">
							<span> Contacts </span>
						</a>
					</li>
				</ul>
				<div className="burger__button"	onClick={toggleMenu}>
					<span className="burger__button-touch"></span>
				</div>
			</nav>
			<div className="over" onClick={toggleMenu}></div>
		</div>
	);
};

export default HeaderMob