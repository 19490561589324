import HeaderDesktop from "./components/Header/HeaderDesktop"
import Footer from "./components/Footer/Footer"
import Slogan from "./views/Slogan/Slogan"
import SloganTwo from "./views/SloganTwo/SloganTwo"
import AboutMe from "./views/AboutMe/AboutMe"
import ExampleProjects from "./views/ExampleProjects/ExampleProjects"

function App() {

  return (
    <div className="App">
      <HeaderDesktop />
      <Slogan />
      <ExampleProjects />
      <AboutMe />
      <SloganTwo />
      <Footer />
    </div>
  );
}

export default App
