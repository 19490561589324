import React from 'react'

const Square = () => {
	return (
		<>
			<div className="back side">React</div>
			<div className="left side">Frontend</div>
			<div className="right side">Developer</div>
			<div className="top side">JS</div>
			<div className="bottom side">Redux</div>
			<div className="front side">Bootstrap</div>
		</>
	);
};

export default Square