import React from 'react'
import sreenshotOne from '../../assets/images/Screenshot_1.png'
import sreenshotTwo from '../../assets/images/Screenshot_2.png'
import sreenshotThere from '../../assets/images/Screenshot_3.png'
import sreenshotMoow from '../../assets/images/MOOW.png'

const ExampleProjects = () => {
	const projectsInfo = [
		{
			id: 4,
			img: sreenshotMoow,
			title: 'MOOW PRO',
			text: 'MOOW is a multi-platform + CRM that combines warehouse booking, instant search for transport and' +
				' special equipment, online services in the ' +
				'business sector, and a product marketplace. Frontend - React, Redux ' +
				'toolkit, Bootstrap, MongoDB, Node.',
			link: 'https://moow.pro/'
		},
		{
			id: 2,
			img: sreenshotTwo,
			title: 'Fave Codes',
			text: 'Production project for Menu restaurants. Frontend - React, Redux toolkit, Bootstrap.',
			link: 'https://favecodes.io/'
		},
		{
			id: 3,
			img: sreenshotOne,
			title: 'ZoomTender',
			text: 'Production project for creating tenders and rates for them. – React, Redux toolkit, Bootstrap / under development ',
			link: 'https://zoomtender.com/'
		},
		{
			id: 1,
			img: sreenshotThere,
			title: 'Theke',
			text: 'Startup project for small business. React, Redux toolkit, Bootstrap, NodeJS(Express), MongoDB / under development ',
			link: 'https://theke.com.ua'
		},
	]
	return (
		<div
			className='projects'
			id='projects'
		>
			<p className='projects-p'>{'<projects>'}</p>
			<div className="projects__wrapper">
				{
					projectsInfo.map((item) => <div
							className="projects__box"
							key={item.id}
						>
							<img
								src={item.img}
								alt={item.title}
								className="projects__box-image"
							/>
							<div className="projects__box-content">
								<h3 className="projects__box-title">
									{item.title}
								</h3>
								<p className="projects__box-text">
									{item.text}
								</p>
								{
									item?.link && <a
										className='projects__box-link'
										href={item?.link}
										target={"_blank"}
									>
										Go to the website =>
									</a>
								}
							</div>
						</div>
					)
				}
			</div>
				<p className="projects__wrapper-dots">...</p>

			<p className='projects-p'>{'<projects>'}</p>
		</div>
	)
}

export default ExampleProjects