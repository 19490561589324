import React from 'react'
import HeaderMob from "./HeaderMob"
import Square from "./Square"

const HeaderDesktop = () => {
	return (
		<div id='home'>
			<HeaderMob />
			<div className="content">
				<h1 className='content__name'>
					Hey! I am Serhii.
					<br />
					I build interactive websites that run across platforms & devices
				</h1>
				<div className="container">
					<Square />
				</div>
				<div className="container-one">
					<Square />
				</div>
				<div className="container-two">
					<Square />
				</div>
			</div>
		</div>
	)
}

export default HeaderDesktop